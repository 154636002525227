<div [class.caption]="caption()">
	@if(caption()) {
	<label>{{caption()}}</label>
	}
	<app-button icon="mdi mdi-pencil" [size]="size()" [label]="label()" (click)="buttonClick()"></app-button>
</div>

@if(showDialog()) {
<app-dialog label="Asset" (onClose)="dialogClose($event)" [dimensions]="{width:500,height:280}" [footer]="[{type:'action', label:'Update', id:'update', icon:'mdi mdi-check'}]">
	<app-data-form [dataForm]="dataForm" />
</app-dialog>
}
