<article>
	@if(isLoading()) {
	<app-info-panel>Please wait...</app-info-panel>
	} @else {

	@if(stats() === null) {
	<app-info-panel>
		<p>No data available.</p>
	</app-info-panel>
	} @else {
	<main>

		<section>
			<h1>Hourly Occupancy</h1>
			<app-setpoint-query-occ-squares [stats]="stats()"></app-setpoint-query-occ-squares>
		</section>

		<section>
			<h1>Location</h1>
			<app-setpoint-query-occ-plan [stats]="stats()"></app-setpoint-query-occ-plan>
		</section>

		<section>
			<h1>Tenancy</h1>
			<app-setpoint-query-occ-tenserv [stats]="stats()"></app-setpoint-query-occ-tenserv>
		</section>

		<section>
			<h1>Configuration</h1>
			<app-setpoint-query-occ-config [stats]="stats()"></app-setpoint-query-occ-config>
		</section>

		<section>
			<h1>Rating</h1>
			<app-rating-ae> </app-rating-ae>
		</section>

	</main>
	}
	<app-info-panel *ngIf="isAdmin && packets()?.length" icon="admin">
		<div>
			<p> Telemetry exists, there are {{packets()?.length}} data points.</p>
			<button pButton type="button" (click)="rebuild(asset().id, false)" icon="mdi mdi-database-refresh-outline" class="p-button-outlined p-button-sm mr-1" label="Rebuild for these dates" tooltipPosition="bottom" pTooltip="rebuild data for dates seleted"></button>
			<button pButton type="button" (click)="rebuild(asset().id, true)" icon="mdi mdi-database-refresh-outline" class="p-button-outlined p-button-sm mr-1" label="Reset occupancy data for asset" pTooltip="delete current data and rebuild" tooltipPosition="bottom"></button>
			<!--<button pButton type="button" (click)="rebuild()" icon="mdi mdi-database-refresh-outline" class="p-button-outlined p-button-sm" label="Rebuild ALL assets"></button>-->
		</div>
	</app-info-panel>
	}
</article>
