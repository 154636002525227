import { User } from "./user";
import { CmsItemBlock, BuildCmsItemBlock } from "./cms-item-block";

export class CMSItem {
    id: number;
    title: string;
    blocks: BuildCmsItemBlock[] = [];
    createdAt: Date;
    createdBy: User;
    category: string;
    stage: string;

    constructor(data?:any) {
        if (data) {
            if (data.master) {
                // Data received by API
                this.id = data.master.id;
                this.title = data.master.title;
                this.blocks = data.rows ? data.rows.map( row => new BuildCmsItemBlock(row)) : [];
                this.createdAt = new Date(data.master.createdAt);
                this.category = data.master.category;
                this.stage = data.master.stage;
            } else {
                // Shortcut manual data setup
                this.id = data.id;
                this.title = data.title;
                this.blocks = data.blocks ? data.blocks : [];
                this.createdAt = new Date(data.createdAt);
                this.category = data.category;
                this.stage = data.stage;
            }
        } else {
            this.createdAt = new Date();
        }
    }
}