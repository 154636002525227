import { Component, Input, OnInit, input, signal } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { IgetHourlyForAssetFromRangeResponse, OccupancyService } from 'app/shared/occupancy.service';
import { SetpointQueryDateRange } from '../setpoint-query.component';
import { APIService } from 'app/shared/api.service';
import { Packet } from 'app/classes/packet';
import { Telemetry } from 'app/classes/telemetry';

@Component({
  selector: 'app-setpoint-query-occ',
  templateUrl: './setpoint-query-occ.component.html',
  styleUrls: ['./setpoint-query-occ.component.css']
})
export class SetpointQueryOccComponent implements OnInit {

  asset = input.required<Asset>();
  packets = input.required<Telemetry[]>();
  isLoading = signal<boolean>(false);
  stats = signal<IgetHourlyForAssetFromRangeResponse>(null);

  _setpointQueryDateRange: SetpointQueryDateRange;

  // Allow  dates from users custom selection 
  /**
   *  @deprecated
   **/
  @Input()
  public set setpointQueryDateRange(v: SetpointQueryDateRange) {

  }

  // Allow dates fropm the presets
  @Input()
  public set chartPresetDate(v: SetpointQueryDateRange) {
    this._setpointQueryDateRange = v;
    if (v) {
      this.get();
    }
  }


  isAdmin: boolean;

  constructor(private occupancyService: OccupancyService, private apiService: APIService) {

  }

  ngOnInit(): void {
    this.isAdmin = this.apiService.isAdmin();
  }

  get() {
    this.isLoading.set(true);
    const from = this._setpointQueryDateRange.from;
    const to = this._setpointQueryDateRange.to;
    this.occupancyService
      .getHourlyForAssetFromRange(this.asset().id, from, to)
      .then(r => {
        this.isLoading.set(false);
        console.log('Got', r);
        this.stats.set(r);

      });
  }

  async rebuild(assetId: number = null, resetData = false) {
    if (!this.isAdmin) {
      return;
    }
    this.apiService.toastSuccess('building occupancy hours', '');
    this.isLoading.set(true);
    await this.occupancyService.rebuildHourlyForAsset(assetId, this._setpointQueryDateRange.from, this._setpointQueryDateRange.to, resetData);
    this.apiService.toastSuccess('building done', '');
    this.get();
    this.isLoading.set(false);
  }
}
