/**
 * Asset
 * @version 1.0.1
 * @author tonymporter@gmail.com
 */
import { Gateway } from './gateway';
import { Purpose } from './purpose';
import { AssetPreset } from './asset-preset';
import { Workplan } from './workplan';
import { SettingItem } from './setting-item';
import { Annotation } from "./annotation";
import { AssetService } from 'app/shared/asset.service';
import moment from 'moment';
import { IDatesFromTo } from 'app/interfaces/dates-from-to';
import { IRedisAsset } from 'app/shared/redis.service';

const S3_HOST = 'https://s3.eu-west-2.amazonaws.com';

export class Asset {
	annotation: Annotation;
	assetType_id: number;
	assetType: number;
	assetTypeTitle: string;
	checked: boolean;
	createdAt: Date;
	display: string;
	daysOld: number;
	filtered: boolean;
	formattedValue: string;
	gateway: Gateway;
	gateway_id: string;
	gatewayTitle: string;
	id: number;
	icon: string;
	identifier: string;
	inError: string;
	isSetpointable: boolean;
	labels: string;
	location: string;
	min: number;
	max: number;
	metobsid: number;
	minAlert: string;
	maxAlert: string;
	parentAsset_id: number;
	purpose: Purpose;
	purposeId: number;
	presets: AssetPreset[] = [];
	ref: string;
	setPointCount: number;
	setpoints: any[];
	setpointRuleCount: number;
	settings: SettingItem[] = [];
	site_id: number;
	siteTitle: string;
	status: string;
	svg: any;
	title: string;
	typeOf = 'Asset';
	updatedAt: Date;
	value: string;
	valueAsNumber: number;
	rag: string;
	redis: IRedisAsset;
	ingestRatePerDay: number;

	constructor(data?: any) {

		this.purpose = new Purpose();
		this.gateway = new Gateway();

		if (typeof data === 'number') {
			this.id = data;
		} else if (data) {
			this.id = data.id;
			this.display = data.display;
			this.title = data.display || data.title;
			this.assetType = data.assetType;
			this.assetType_id = data.assetType_id;
			this.icon = AssetService.ICONS[this.assetType_id];
			this.assetTypeTitle = data.assetTypeTitle;
			this.identifier = data.identifier;
			this.inError = data.inError;
			this.svg = data.svg;
			this.labels = data.labels || '';
			if (data.updatedAt) {
				this.updatedAt = new Date(data.updatedAt);
			}
			this.value = data.value;
			this.gateway_id = data.gateway_id;
			this.siteTitle = data.siteTitle || data.site?.title;
			this.site_id = data.site_id || data.siteId || data.site?.id;
			this.status = data.status;
			if (data.createdAt) {
				this.createdAt = new Date(data.createdAt);
			}
			this.location = data.location;
			this.isSetpointable = data.isSetpointable;
			this.ref = data.ref;
			this.metobsid = data.metobsid;

			this.purpose = new Purpose({ id: data.purpose_id, title: data.purposeTitle });

			this.gateway.id = data.gateway_id || data.gateway?.id;
			this.gateway.title = data.gatewayTitle || data.gateway?.title || data.gTitle;
			this.gateway.site.org.id = data.orgId || data.org?.id;
			this.gateway.site.id = data.site_id || data.siteId || data.site?.id;
			this.gateway.site.title = data.siteTitle || data.site?.title;
			this.maxAlert = data.maxAlert;
			this.minAlert = data.minAlert;
			this.rag = data.rag;
			this.setpointRuleCount = data.setpointRuleCount;
			this.daysOld = +(((+new Date() - +new Date(data.updatedAt)) / (1000 * 60 * 60 * 24)).toFixed(1));

			if (data.settings) {
				this.settings = data.settings;
			}
			if (data.setting) {
				try {
					// this is a string version
					const arrayOfSettings = data.setting.split('|');
					this.settings = arrayOfSettings.map(setting => {
						const settingArray = setting.split('~');
						return new SettingItem({ setting: settingArray[0], value: settingArray[1] });
					});
				} catch (e) { }
			}

			const rate = this.settings?.find(s => s.setting === 'ingest_rate_day');
			if (rate) {
				this.ingestRatePerDay = +rate.value;
			}

			if (this.purpose.id) {
				this.valueAsNumber = this.purpose.asNumber(this.value);
			} else {
				this.valueAsNumber = this.value && isNaN(this.value as any) ? null : +this.value;
			}
		}

		if (this.id === 1) {
			this.identifier = 'weather';
			this.title = 'Outside Temperature';
		}
	}

	/**
	 * Get the first and last date for ingested data.
	 * - Use redis for accurate data or use createdAt and updatedAt otherwise
	 * 
	 * @returns {from: Date, to: Date}
	 */
	getLastFirstTelemeteyDates(): IDatesFromTo {
		if (this.redis?.firstat) {
			return { from: this.redis.firstat, to: this.redis.lastat };
		} else {
			return { from: this.createdAt, to: this.updatedAt };
		}
	}

	static formattedValue(value: any, assetTypeId: number, purposeId: number, updatedAt: Date, ident: string) {

		let display = value;

		switch (assetTypeId) {
			case 1:
				if (purposeId === 12) {
					//presence button
					display = moment(updatedAt).fromNow();
				}
				break;
			case 17:
				display = (+value === 0 ? 'Closed' : 'Open');
				break;
			case 3:
			case 51:

				display = Number(display).toFixed(0);
				break;
			case 18:
			case 20:

			case 4:
			case 7:
			case 8:
			case 10:
			case 11:
			case 12:
			case 25:
			case 27:
			case 33:
			case 38:
			case 45:
			case 46:
			case 31:
			case 42: // Generic string
			case 43: // camera
			case 44: // camera
			case 59:
			case 67:
				break;
			case 23:
				if (ident && ident.endsWith('_moisture')) {
					display = +value ? 'No Leak' : 'Leak';
				} else {
					display = +value ? 'Leak' : 'No Leak';
				}
				break;
			case 50:
				display = +value === 1 ? 'In Use' : 'Free';
				break;
			case 68:
				display = (+value === 0) ? 'No gas detected' : 'Gas detected';
				break;
			case 48:
				display = display ? +display === +display ? value : moment(value).format('DD/MM/YY') : '';
				break;
			case 70:
				break;
			default:
				display = Number(display).toFixed(1);
				break;
		}

		return display;
	}

	displayValue() {
		return Asset.formattedValue(this.value, this.assetType_id, this.purpose.id, this.updatedAt, this.identifier) + this.displayPost();
	}

	displayPost() {
		let post = '';
		switch (this.assetType_id) {
			case 2:
			case 19:
				post = 'c';
				break;
			case 8:
				post = '%';
				break;
		}

		return post;
	}

	asNumeric() {
		return +this.value;
	}
}

export class Camera extends Asset {
	last_image: string;
	last_image_url: string;
	workplan: Workplan;

	constructor(data?: any) {
		super(data.asset);
		this.workplan = new Workplan();
		this.presets = data.presets.map(preset => new AssetPreset(preset));
		this.last_image = data.asset.last_image;
		if (this.last_image) {
			this.last_image_url = this.last_image.indexOf('session') > 0
				? `${S3_HOST}/4d-public/sessions/${this.last_image}` : `${S3_HOST}/4d-assets/${this.last_image}`;
		} else {
			this.last_image_url = `${S3_HOST}/4d-images-cms/no-image.png`;
		}
	}
}

export class Occupancy extends Asset {
	constructor(data?: any) {
		super(data.asset);
	}

	override asNumeric() {
		return this.value.toLowerCase() === 'in use' ? 1 : 0;
	}
}

export class AssetType {
	id: number;
	title: string;
	category: string;
	dataType: string;
	icon?: string;
	min?: number;
	max?: number;
	vType?: string;
	uom?: string;
	outofdateMins: number;
	isHidden?: boolean;
	notes?: string;
	checked?: boolean;

	constructor(data?: any) {
		if (data) {
			this.id = data.id;
			this.title = data.title;
			this.category = data.category;
			this.dataType = data.dataType;
			this.uom = data.uom;
			this.min = data.min;
			this.max = data.max;
			this.vType = data.vType;
			this.outofdateMins = data.title.toLowerCase().indexOf('daily') ? 60 * 25 : 60;
			this.isHidden = data.isHidden;
			this.notes = data.notes;
		}
	}
}


export interface IAssetSimple {
	id: number;
	title: string;
}
